<template>
  <section>
    <b-row class="match-height">
      <b-col xl="3" md="6"> </b-col>

      <b-col xl="6" md="6" v-for="(result, id) in dataResult" :key="id">
        <b-card class="card-congratulation-medal">
          <h5>Anda Telah Menyelesaikan Ujian 🎉</h5>
          <b-card-text class="font-small-3">
            Catatan: Nilai Score dari 0 - 100
          </b-card-text>
          <h3 class="mb-75 mt-2 pt-50 text-center">
            <b-link>Skor: {{ result.score }}</b-link>
          </h3>
          <b-row>
            <b-col sm="3"></b-col>
            <b-col sm="6">
            <router-link to="/user/list_hasil_tryout" class="text-center">
              <b-button variant="primary"> Lihat Selengkapnya </b-button>
            </router-link>
          </b-col>
          </b-row>
          <b-img
            src="https://cdn-icons-png.flaticon.com/512/2827/2827014.png"
            class="congratulation-medal"
            height="60"
            width="60"
            alt="Medal Pic"
          />
        </b-card>
        <b-card no-body class="card-statistics">
          <b-card-body class="statistics-body">
            <b-row>
              <b-col xl="2" sm="6"> </b-col>
              <b-col xl="4" sm="6" class="mb-2 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="success">
                      <feather-icon size="24" icon="CheckCircleIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ result.correct }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0"> Benar </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <br />
              <b-col xl="4" sm="6">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="danger">
                      <feather-icon size="24" icon="XIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">{{ result.wrong }}</h4>
                    <b-card-text class="font-small-3 mb-0"> Salah </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BAvatar,
  BImg,
  BCardText,
  BBadge,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
export default {
  components: {
    BCard,
    BImg,
    BCardText,
    BBadge,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      id_session: localStorage.getItem("Uid"),
      id_param: this.$route.params.id,
      dataResult: [],
    };
  },
  mounted() {
    this.checkAuth();
    // process.env.VUE_APP_BACKEND_URL + `questions/auto_finish_exam/?planId=${id_par}&sessionId=${id_ses}`, '_blank'
    this.getAutoScoreExamUser();
    this.getScoreExamUser();
  },
  created() {
    this.getAutoScoreExamUser();
  },
  methods: {
    checkAuth() {
      var sessionCheck = localStorage.getItem("Uid");
      if (sessionCheck == null || sessionCheck == "") {
        this.$router.push({ name: "login" });
      }
    },
    getAutoScoreExamUser() {
      // this.$http.get(
      //       process.env.VUE_APP_BACKEND_URL + `questions/resultexamuser/`
      //       + this.id_param + `/` + this.id_session
      //       )
      //     .then((res) => {
      //     this.dataResult = res.data.data;
      //   });;
      this.$http.post(
        process.env.VUE_APP_BACKEND_URL + `questions/finish_exam`,
        {
          planId: this.id_param,
          sessionId: this.id_session,
        }
      );
    },
    getScoreExamUser() {
      this.$http
        .get(
          process.env.VUE_APP_BACKEND_URL +
            `questions/resultexamuser/` +
            this.id_param +
            `/` +
            this.id_session
        )
        .then((res) => {
          this.dataResult = res.data.data;
        });
    },
  },
};
</script>
